import React, { createRef, useEffect, useState } from "react"
import Webform from "gatsby-drupal-webform"

import WebformFieldCheckbox from "./Webform/WebformFieldCheckbox"
import WebformFieldCheckboxes from "./Webform/WebformFieldCheckboxes"
import WebformFieldEmail from "./Webform/WebformFieldEmail"
import WebformFieldText from "./Webform/WebformFieldText"
import WebformFieldTextarea from "./Webform/WebformFieldTextarea"
import WebformFieldPhone from "./Webform/WebformFieldPhone"
import WebformFieldSelect from "./Webform/WebformFieldSelect"
import WebformFieldRadios from "./Webform/WebformFieldRadios"
import WebformFieldMarkup from "./Webform/WebformFieldMarkup"
import WebformSubmit from "./Webform/WebformSubmit"
import WebformFieldDate from "./Webform/WebformFieldDate"

const Form = ({ webform, ...props }) => {
  const [status, setStatus] = useState(null)
  const [message, setMessage] = useState("")
  const [formKey, setFormKey] = useState(new Date().getTime())
  const messageRef = createRef()

  const successHandler = response => {
    if (response.settings) {
      setStatus("success")
      setMessage(response.settings.confirmation_message)
    }
  }

  const validateHandler = event => {
    setStatus("loading")
    event.preventDefault()

    let isValid = event.currentTarget.checkValidity()

    if (!isValid) {
      setStatus("error")
      setMessage(
        "Au moins un champ de ce formulaire n'est pas conforme. Merci de contrôler votre saisie."
      )
    }

    return isValid
  }

  useEffect(() => {
    if (status === "success") {
      setFormKey(new Date().getTime())
    }
  }, [status])

  useEffect(() => {
    if (message !== "" && messageRef.current) {
      messageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      })
    }
  }, [message])

  return (
    <div>
      <div className="mb-8 font-important font-semibold">
        Les champs marqu&eacute;s d&apos;un ast&eacute;risque (*) sont
        obligatoires
      </div>

      <div key={formKey}>
        <Webform
          webform={webform}
          customComponents={{
            textfield: WebformFieldText,
            date: WebformFieldDate,
            email: WebformFieldEmail,
            tel: WebformFieldPhone,
            checkbox: WebformFieldCheckbox,
            checkboxes: WebformFieldCheckboxes,
            select: WebformFieldSelect,
            textarea: WebformFieldTextarea,
            radios: WebformFieldRadios,
            webform_markup: WebformFieldMarkup,
          }}
          buttonComponent={() => <WebformSubmit status={status} />}
          endpoint={`${process.env.API_URL}${process.env.WEBFORM_ENDPOINT}`}
          onSuccess={successHandler}
          onValidate={validateHandler}
          {...props}
        />
      </div>

      {message !== "" && (
        <div
          ref={messageRef}
          className={`${
            status === "success"
              ? "bg-secondary"
              : status === "error"
              ? "bg-flashred"
              : "bg-primary"
          } mt-8 p-4 bg-opacity-75 text-white`}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
    </div>
  )
}

export default Form
